@import url(https://fonts.googleapis.com/css?family=Fjalla+One);
@import "./spinner.scss"; //Personalizar variables
// $body-bg: #000;
$body-bg: #000;
$green: #70f04c;
// Import Bootstrap and its default variables

:root {
  --bg-left: #01579b;
  --bg-right: #0288d1;
  --bg-main: white;
}

.ant-table-tbody > tr > td {
  padding: 0px 5px !important;
  overflow-wrap: break-word !important;
}

.file {
  display: none;
  color: white;

  & + label {
    border-radius: 5px;
    padding: 8px 10px;
    font-family: Arial;
    font-size: 12px;
    color: #fff;
    width: 100%;
    height: 100;
    background: #28a745;
    text-align: center;

    &:after {
      background: green;
    }
    &:hover {
      background: green;
    }
  }
}

.spand {
  font-size: 12px;
  position: absolute;
  padding-left: 30px;
  text-align: center;
  left: 0;
  // background:#fff;
  color: black;
}
.dropdown-menu {
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: $break-small) {
    left: 0;
    right: auto;
  }
  @media screen and (min-width: $break-large) {
    right: 0;
    left: auto;
  }
}
li {
  margin-top: 10px;
}
ul {
  list-style: none;
  padding-left: 0;
}

.btnsearch {
  background-color: transparent;

  color: #ffffff;
  font-family: Arial;
}
.btn {
  border-top-width: 1px !important;
  // padding: 5px 10px !important;
  padding: 2.5px 7.5px !important;
}

// table {
//   table-layout: fixed;
//   width: 250px;
//   overflow-x: scroll;
//   white-space: nowrap;
//   position: relative;
// }

.header-fixed {
  position: fixed;
  top: 0px;
  display: none;
  background-color: white;
}

// td {
//   overflow-y: auto !important;
// }

// th {
//   color: white !important;
//   $break-small: 600px;
//   $break-large: 700px;

//   @media screen and (max-width: $break-small) {
//     width: 100px !important;
//   }
//   background-color: var(--bg-right) !important;
// }
th {
  color: white !important;
  background-color: var(--bg-right) !important;
}
.tabla_libro_diario th {
  color: black !important;
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: var(--bg-right) !important;
  border-color: #4e555b !important;
}

.btn-secondary {
  color: #fff !important;
  background-color: var(--bg-right) !important;
  border-color: #6c757d !important;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff !important;
  background-color: var(--bg-right) !important;
  border-color: #545b62 !important;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5) !important;
}

.table {
  width: auto !important;
}

.tableConfig {
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: 800px) {
    overflow-x: scroll !important;

    width: 700px !important;
  }
  @media screen and (min-width: $break-large) {
  }
}

.table {
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: $break-small) {
    overflow-x: scroll !important;
    display: block !important;
    width: 700px !important;
  }
  @media screen and (min-width: $break-large) {
    width: 100% !important;
  }
}

.env-input-file {
  height: auto !important;
}

.justify-content-center {
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: $break-small) {
    justify-content: auto !important;
  }
}

.center-pagination {
  $break-small: 600px;
  $break-large: 700px;

  @media screen and (max-width: $break-small) {
    justify-content: auto !important;
  }
  @media screen and (min-width: $break-large) {
    justify-content: center !important;
  }
}

.max-content {
  width: max-content;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}

.blue-50 {
  background: #e3f2fd;
}
.blue-100 {
  background: #bbdefb;
}
.blue-200 {
  background: #90caf9;
}
.blue-300 {
  background: #64b5f6;
}
.blue-400 {
  background: #42a5f5;
}
.blue-500 {
  background: #2196f3;
}
.blue-600 {
  background: #1e88e5;
}
.blue-700 {
  background: #1976d2;
}
.blue-800 {
  background: #1565c0;
}
.blue-900 {
  background: #0d47a1;
}
.blue-1000 {
  background: rgb(8, 52, 119);
  color: white;
}

.blue-1100 {
  background: #82b1ff;
}
.blue-1200 {
  background: #448aff;
}
.blue-1400 {
  background: #2979ff;
}
.blue-1700 {
  background: #2962ff;
}

.blue-gray-50 {
  background: #eceff1;
}
.blue-gray-100 {
  background: #cfd8dc;
}
.blue-gray-200 {
  background: #b0bec5;
}
.blue-gray-300 {
  background: #90a4ae;
}
.blue-gray-400 {
  background: #78909c;
}
.blue-gray-500 {
  background: #607d8b;
}
.blue-gray-600 {
  background: #546e7a;
}
.blue-gray-700 {
  background: #455a64;
}
.blue-gray-800 {
  background: #37474f;
}
.blue-gray-900 {
  background: #263238;
}

.ant-table-footer {
  padding: 0px !important;
}
.MuiButtonBase-root {
  outline: none !important;
}
.ant-notification {
  z-index: 999999999 !important;
}
.MuiDialog-root {
  z-index: 1024 !important;
}

.tablefe {
  overflow: hidden;
}
.tablefe tr th,
.tablefe tr td {
  border: 1px solid rgba(200, 200, 200, 0.5);
}

.tableReport {
  min-width: 1024px;
  overflow: hidden;
}
.tableReport tr th {
  height: 60px !important;
  font-size: 12px;
  border-left: 1px solid rgba(200, 200, 200, 0.5);
}
.tableReport tr td {
  font-size: 11px;
  border-left: 1px solid rgba(200, 200, 200, 0.5);
  padding: 0 5px;
}
.tableReport tr td:last-child {
  border-right: 2px solid rgba(200, 200, 200, 0.5);
  padding: 0 5px;
}
