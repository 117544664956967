// SPINNER
.spinner_container {
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 10px;
  .spinner_content {
    position: relative;
    color: #0288d1;
    width: 150px;
    height: 150px;
    display: grid;
    place-content: center;
    letter-spacing: 1px;
    font-size: 18px;
    margin: 0 auto;
    .loading {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border-left: 1px solid #0288d1;
      animation: rotate 1s linear infinite;
    }
    .loading2 {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border-left: 1px solid #0288d1;
      animation: rotate2 1s linear infinite;
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
