.content {
  padding: 50px;
}
.tableClaims thead tr th,
.tableClaims tbody tr td,
.tableClaims tfoot tr td {
  background-color: #fff !important;
  color: initial !important;
  text-align: center;
  border: 1px solid #ddd;
  margin: 0;
}
.tableClaims tbody tr td,
.tableClaims tfoot tr td {
  text-align: left;
}
.tableClaims .title {
  font-weight: 400;
  font-size: 1.1rem;
  background-color: #9cdafc !important;
  padding: 5px;
}
.tableClaims .titleHead {
  font-weight: 400;
  font-size: 1.2rem;
  background-color: #0288d1 !important;
  padding: 10px 5px;
  color: #fff !important;
}
.tableClaims .freeCell {
  border: none;
  border-left: 1px solid #ddd;
}
.tableClaims p {
  color: #757575;
  font-weight: 600;
  padding: 2px;
  margin: 0;
}
.tableClaims span {
  color: #757575;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.tableClaims img {
  max-height: 75px;
  max-width: 75px;
}
.tableClaims .caption {
  font-size: 0.75rem;
}
.tableClaims .text-center {
  text-align: center;
}
