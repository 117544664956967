#sticky-header-inventory-1,
#sticky-header-inventory-2 {
  position: sticky;
  left: 0;

  background-color: #f5f5f5 !important;
  z-index: 10;
  color: #000 !important;
}

#sticky-header-inventory-1 {
  max-width: 100;
}

#sticky-header-inventory-2 {
  left: 100px;
}
